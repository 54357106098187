import React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const heroImages = [
      data.heroDesktop.childImageSharp.fixed,
      {
         ...data.heroMobile.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Careers | Painted Rhino | Fiberglass Manufacturing Company"
            description="Are you interested in joining the Painted Rhino team? Find out about our fiberglass manufacturing job openings."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BackgroundImage fixed={heroImages} className="w-full py-22 md:py-28">
            <div className="container relative">
               <header className="text-center max-w-3xl mx-auto">
                  <h1 className="text-white mb-5">Career Opportunities</h1>
                  <p className="text-white mb-0">
                     Painted Rhino Inc. is a family owned and operated fiberglass job shop. We have been in business for 25 years and have employees
                     that have been here just as long. If you are interested in joining the Painted Rhino team please contact us with your resume.
                  </p>
               </header>
            </div>
         </BackgroundImage>

         <section className="py-20 md:py-24 bg-gray-100">
            <div className="container">
               <header className="max-w-3xl mb-10 md:mb-12">
                  <h2>Job opportunities are currently available in the following departments:</h2>
               </header>
               <div className="bg-white pt-10 md:pt-14 pb-8 md:pb-12 px-6 md:px-12 mb-8 lg:mb-12">
                  <h3>Laminating Department</h3>
                  <p>
                     This department includes: open mold and closed mold technicians, chopper gun operators, Gel coaters, rollers, hand laminators,
                     and mold repair specialist. Also if your good with your hands, committed and have the right attitude we will be willing to train.
                     In this department you would be part of a team running production for the various clients we have.
                  </p>

                  <ButtonSolid data="modal-careers" text="Apply Now" />
               </div>

               <div className="bg-white pt-10 md:pt-14 pb-8 md:pb-12 px-6 md:px-12 mb-8 lg:mb-12">
                  <h3>Tooling Department</h3>
                  <p>
                     This department includes: pattern makers, tooling technicians, mold makers, designers, and artist. In this department you would
                     be required to think out of the box to help customers and production come up with more efficient ways of designing and producing
                     their product. Also you would work closely with upper management and quality control to assure that the customer is getting
                     exactly what they want when they want.
                  </p>
                  <ButtonSolid data="modal-careers" text="Apply Now" />
               </div>

               <div className="bg-white pt-10 md:pt-14 pb-8 md:pb-12 px-6 md:px-12 mb-8 lg:mb-12">
                  <h3>Finishing Department</h3>
                  <p>
                     In this department you would most likely be in a production environment where you are finishing daily what production is making.
                     This is a fast paced environment where bodywork skills, sanding, polishing, and buffing are needed. Again for the right like
                     minded individual we can train. Employees from other industries such as: Auto body shops, cabinet makers, boat repair, RV repair,
                     and automotive painters typically excel in this department.
                  </p>
                  <ButtonSolid data="modal-careers" text="Apply Now" />
               </div>

               <div className="bg-white pt-10 md:pt-14 pb-8 md:pb-12 px-6 md:px-12">
                  <h3>Trimming Department</h3>
                  <p>
                     This department requires precision and patience. One slip with a router or trimmer could ruin a expensive part. This environment
                     is dirty yet rewarding. You give the product its final shape. Employees that excel in this department typically have hand tool
                     experience along with carpentry experience. Individual may be required to read prints and drawings as well as use measuring
                     devices. Reading tape measures, calipers, and scales will be a requirement in this department. Training is available for the
                     right candidate.
                  </p>
                  <ButtonSolid data="modal-careers" text="Apply Now" />
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Homepage_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "reviews/reviews-hero-desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      heroMobile: file(relativePath: { eq: "reviews/reviews-hero-mobile.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
   }
`;
